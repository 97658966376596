import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
// import { useIntl } from "react-intl"

import { IntlContext } from "../../intl"
import Layout from "../layout"
import SEO from "../seo"

export const query = graphql`
	query($id: String!) {
		prismicPrivacy(id: { eq: $id }) {
      uid
      lang
			data {
				title {
					text
				}
				content {
					html
				}
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicPrivacy {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const PrivacyPage = ({ location, data }) => { console.log(data.prismicPrivacy)

	// const intl = useIntl()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicPrivacy.lang);
    setAlts(data.prismicPrivacy.alternate_languages || [])
	}, [changeLocale, setAlts, data])

  return (
    <Layout container={false}>
      <SEO 
        lang={data.prismicPrivacy.lang} 
        title={data.prismicPrivacy.data.title.text} 
        meta={[{
          "http-equiv": "content-language", 
          content: data.prismicPrivacy.lang
        }]} 
        links={(data.prismicPrivacy.alternate_languages||[]).concat([data.prismicPrivacy]).map(alt => ({
          rel: "alternate", 
          hreflang: alt.lang, 
          href: `https://www.customerradius.com${alt.url}`
        })).concat([{
          rel: "alternate", 
          hreflang: "x-default", 
          href: "https://www.customerradius.com/privacy"
        }])} />
      <div className="section has-background-info">
        <div className="container">
          <div className="column is-half is-offset-one-quarter">
            <h1 className="title has-text-centered has-text-white">
              {data.prismicPrivacy.data.title.text}
            </h1>
            <div className="card">
              <div className="card-content">
                <div className="content" dangerouslySetInnerHTML={{ __html: data.prismicPrivacy.data.content.html }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)}

export default PrivacyPage
